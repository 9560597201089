import React, { useEffect } from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useWidget } from 'components/Widget/useWidget';
import ninjaApi from 'store/redux/apis/ninja.api';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';
import ChartBody from 'modules/DashboardModule/charts/components/ChartBody';
import { getMinTwoDaysRange } from 'components/DateRangePicker/components/disableDateRange';

const WooCommerceChartContent = ({ height }) => {
  const [getIntegrationsReports, { data: data = [], isLoading }] =
    ninjaApi.useLazyIntegrationsReportsQuery();
  const { filters, chartConfig, widget_key } = useWidget();
  const { date_from, date_to, setDate } = useWidgetDate(widget_key);

  useEffect(() => {
    let adjustedDateFrom = date_from;
    let adjustedDateTo = date_to;

    if (chartConfig?.load_chart) {
      if (date_from === date_to) {
        ({ date_from: adjustedDateFrom, date_to: adjustedDateTo } = getMinTwoDaysRange(
          date_from,
          date_to
        ));
        setDate({
          date_from: adjustedDateFrom,
          date_to: adjustedDateTo,
        });
      }
    }

    const params = {
      ...filters,
      integration: 'woocommerce',
      load_chart: chartConfig?.load_chart,
      date_from: adjustedDateFrom,
      date_to: adjustedDateTo,
    };

    if (chartConfig?.load_chart) {
      params.group_by = 'date';
    }

    if (filters?.date_from) {
      getIntegrationsReports(params).unwrap();
    }
  }, [filters, chartConfig?.load_chart]);

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!data?.data?.length) {
    return <NoResultFound height={height} />;
  }

  return <ChartBody height={height} data={data.data} />;
};

export default WooCommerceChartContent;
