import NinjaTable from 'components/tables/NinjaTable';
import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import Widget from 'components/Widget/Widget';
import { JustValueFormatter } from 'components/tables/NinjaTable/formatters';
import { TotalValueFormatter } from 'components/tables/NinjaTable/formatters';
import { CountryFormatter } from 'modules/CRMModule/formatters';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import { NameDefinition } from 'components/NameDefinition';

const SalesByCountry = ({ height, index, movingButton, data, configuration }) => {
  const { dates } = useWidgetDate('product_sales' + index);

  const columns = [
    {
      key: 'country',
      name: 'Country',
      sortable: true,
      renderCell: ({ row }) =>
        row.country ? <CountryFormatter value={row.country} editable={false} /> : 'N/A',
      renderSummaryCell: () => 'Totals',
    },
    {
      key: 'gross_income',
      name: 'Gross Income',
      minWidth: 100,
      sortable: true,
      renderCell: JustValueFormatter,
      renderSummaryCell: TotalValueFormatter,
    },
    {
      key: 'gross_profit',
      name: 'Gross Profit',
      minWidth: 100,
      sortable: true,
      renderCell: JustValueFormatter,
      renderSummaryCell: TotalValueFormatter,
    },
    {
      key: 'orders_count',
      name: <NameDefinition name='Orders Count' definiton='Number of orders.' />,
      // sortable: true,
      renderCell: ValueFormatter,
      renderSummaryCell: ValueFormatter,
    },
  ];

  return (
    <Widget
      icon={
        data?.defaultFilter?.integration_type ? (
          <img
            src={`/icons/integrations/${data?.defaultFilter?.integration_type}.svg`}
            className='w-5 h-5 '
          />
        ) : null
      }
      tableWidget
      integrationType={data?.defaultFilter?.integration_type}
      widget_key={'product_sales' + index}
      title={data.title}
      index={index}
      movingButton={movingButton}
      deleteWidget={true}
      data={data}
      default_filter={data.defaultFilter}
      configuration={configuration}
      disableDateFilter
      disableStatusFilter
      disableAccountsFilter
      integrationsSelect
      DisableFilterButton={false}
      design={false}
    >
      <NinjaTable
        columns={columns}
        dataProvider='integrationSalesByCountry'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        maxHeight={height}
        footerProps={{ readOnlyPagination: true }}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default SalesByCountry;
