import NinjaTable from 'components/tables/NinjaTable';
import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import Widget from 'components/Widget/Widget';
import { DotDigitFormatter, JustValueFormatter } from 'components/tables/NinjaTable/formatters';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import { OwerflowNameFormatter } from 'components/tables/NinjaTable/formatters';
import { NameDefinition } from 'components/NameDefinition';

const ProductSales = ({ height, index, movingButton, data, configuration }) => {
  const { dates } = useWidgetDate('product_sales' + index);

  const columns = [
    {
      key: 'product_title',
      name: 'Product Title',
      resizable: true,
      width: 250,
      sortable: true,
      renderCell: ({ row }) => {
        return (
          <OwerflowNameFormatter
            title={row.product_title}
            className='!bg-background-light rounded-xl  p-1.5 py-2.5 text-xs !font-semibold '
          />
        );
      },
      renderSummaryCell: () => 'Totals',
    },
    {
      name: 'Gross Income',
      key: 'gross_income',
      type: 'income',
      renderCell: JustValueFormatter,
      renderSummaryCell: JustValueFormatter,
      sortable: true,
      resizable: true,
    },
    {
      key: 'net_profit',
      name: 'Net Profit',
      type: 'profit',
      renderCell: DotDigitFormatter,
      renderSummaryCell: DotDigitFormatter,
      sortable: true,
      minWidth: 120,
      currency: true,
    },
    {
      key: 'orders_count',
      name: (
        <NameDefinition
          name='Orders Count'
          definiton='Number of orders placed for a specific product'
        />
      ),
      // sortable: true,
      renderCell: ValueFormatter,
      renderSummaryCell: ValueFormatter,
    },
    {
      key: 'net_quantity',
      name: (
        <NameDefinition
          name='Net Quantity'
          definiton='Net Quantity in product sales refers to the total number of units sold after accounting for returns, refunds, or cancellations.'
        />
      ),
      // sortable: true,
      renderCell: ValueFormatter,
      renderSummaryCell: ValueFormatter,
    },
  ];

  return (
    <Widget
      icon={
        data?.defaultFilter?.integration_type ? (
          <img
            src={`/icons/integrations/${data?.defaultFilter?.integration_type}.svg`}
            className='w-5 h-5 '
          />
        ) : null
      }
      tableWidget
      integrationType={data?.defaultFilter?.integration_type}
      widget_key={'product_sales' + index}
      title={data.title}
      index={index}
      movingButton={movingButton}
      deleteWidget={true}
      data={data}
      default_filter={data.defaultFilter}
      configuration={configuration}
      disableDateFilter
      disableStatusFilter
      disableAccountsFilter
      integrationsSelect
      DisableFilterButton={false}
      design={false}
    >
      <NinjaTable
        columns={columns}
        dataProvider='integrationProductSales'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        maxHeight={height}
        footerProps={{ readOnlyPagination: true }}
        defaultSortColumns={{ columnKey: 'gross_income', direction: 'DESC' }}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default ProductSales;
