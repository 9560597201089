import React from 'react';
import { Typography } from 'antd';
import { NameDefinition } from 'components/NameDefinition';
import Icon from '@mdi/react';

const WidgetCard = ({ icon, definition, existDefinition, label, formatter, value, content }) => {
  return (
    <div
      className='max-h-28 h-full gap-2 bg-background-light rounded-xl sm:pl-8 py-3 px-4 flex-grow text-left items-center flex w-full justify-between'
      style={{ minHeight: 90 }}
    >
      <div className=' gap-2 grid h-full justify-between'>
        <div className='flex items-center gap-2'>
          {icon && <Icon path={icon} size={0.8} className='text-orange' />}
          {definition ? (
            <NameDefinition
              placement='bottom'
              name={definition.name}
              definiton={definition.description}
              textSize='text-xs font-semibold w-fit text-background-dark'
            />
          ) : (
            <Typography variant='h4' className='text-xs font-semibold w-fit text-background-dark'>
              {existDefinition ? existDefinition : label}
            </Typography>
          )}
        </div>
        <Typography
          variant='h1'
          className='text-md flex font-medium text-left sm:text-lg text-background-dark w-full h-full'
        >
          <div className='flex flex-wrap flex-center gap-2 show-info-on-hover'>
            {formatter ? formatter(value) : value || 0}
          </div>
        </Typography>
      </div>
      {content}
    </div>
  );
};

export default WidgetCard;
