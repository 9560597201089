import React, { useState, useEffect } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  CartesianGrid,
  Legend,
} from 'recharts';
import { useWidget } from 'components/Widget/useWidget';
import CustomTooltip from 'components/charts/components/ChartTooltip';
import './style.css';

const ChartBody = ({ height, data, dataKey = 'date', formatter, label, tooltipFormatter }) => {
  const { filters, chartConfig } = useWidget();
  const [visibleMetrics, setVisibleMetrics] = useState(chartConfig?.metrics);

  useEffect(() => {
    setVisibleMetrics(chartConfig.metrics);
  }, [chartConfig]);

  const renderBarsAndLines = () => {
    return Object?.keys(chartConfig?.metrics)?.map((key) => {
      const { color, name, type, dashed } = chartConfig.metrics[key];

      if (type === 'line') {
        return (
          <Line
            key={key}
            type='monotone'
            dataKey={key}
            stroke={color}
            fill={color}
            name={name}
            hide={!visibleMetrics[key]?.show}
            yAxisId='right'
            strokeWidth={2}
            strokeDasharray={dashed && '10 10'}
            isAnimationActive
            animationDuration={800}
          />
        );
      }
      return (
        <Bar
          key={key}
          stackId={chartConfig.type === 'stacked' ? 'a' : undefined}
          dataKey={key}
          fill={color}
          name={name}
          hide={!visibleMetrics[key]?.show}
          isAnimationActive
          animationDuration={800}
          radius={[3, 3, 0, 0]}
        />
      );
    });
  };

  return (
    <ResponsiveContainer height={height} width='100%'>
      <ComposedChart
        data={data}
        key={chartConfig.type === 'stacked' ? 'stacked' : 'grouped'}
        stackOffset='sign'
        margin={{
          right: chartConfig.showYAxis ? -20 : 0,
          left: chartConfig.showXAxis ? -20 : 0,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey={dataKey}
          interval={Math.ceil(data.length / 5)}
          tickFormatter={(value) =>
            formatter
              ? formatter(value)
              : new Date(value).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: '2-digit',
                })
          }
          hide={!chartConfig.showXAxis}
        />
        <YAxis hide={!chartConfig.showYAxis} />
        <YAxis hide={!chartConfig.showYAxis} yAxisId='right' orientation='right' />
        <Tooltip
          cursor={{ stroke: '#ff4e00', strokeWidth: 1, strokeDasharray: '3 3' }}
          content={
            tooltipFormatter || (
              <CustomTooltip
                dataKey={dataKey}
                filters={filters}
                metricsList={visibleMetrics}
                label={label}
              />
            )
          }
        />
        {renderBarsAndLines()}
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartBody;
