import React from 'react';
import Widget from 'components/Widget/Widget';
import MetaChartContent from './components/MetaChartContent';

import { metricsList } from '../components/metricsList';

const MetaReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  return (
    <Widget
      className='h-full'
      widget_key={'meta_chart' + index}
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      icon={<img src='/icons/networks/meta.svg' className='w-7 h-7 ' />}
      networkSwitch={false}
      network='facebook'
      configuration={configuration}
      metricsList={metricsList}
      load_chart={true}
      adsFilter
      adsetFilter
      campaignFilter
    >
      <MetaChartContent height={height - 50} />
    </Widget>
  );
};

export default MetaReportsChart;
