import React from 'react';
import Widget from 'components/Widget/Widget';
import PnLByChannelsChartBody from './components/PnLByChannelsChartBody';
import { metricsList } from '../components/metricsList';

const PnLByChannelsChart = ({ height, movingButton, data, configuration, index }) => {
  return (
    <Widget
      disableAccountsFilter
      disableDateFilter
      widget_key={'PnLByChannelsChart' + index}
      title={data.title}
      index={index}
      disableLevelFilter
      disableLimit
      disableStatusFilter
      movingButton={movingButton}
      default_filter={data.defaultFilter}
      configuration={configuration}
      metricsList={metricsList}
      data={data}
      load_chart={true}
      adsFilter
      adsetFilter
      campaignFilter
      showDatePicker
    >
      <PnLByChannelsChartBody height={height - 50} />
    </Widget>
  );
};

export default PnLByChannelsChart;
