import React from 'react';
import Widget from 'components/Widget/Widget';
import GoogleChartContent from './components/GoogleChartContent';
import { metricsList } from '../components/metricsList';

const GoogleReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  return (
    <Widget
      className='h-full'
      widget_key={'google_chart' + index}
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      icon={<img src='/icons/networks/google.svg' className='w-7 h-7 ' />}
      networkSwitch={false}
      network='google'
      configuration={configuration}
      metricsList={metricsList}
      load_chart={true}
    >
      <GoogleChartContent height={height - 50} />
    </Widget>
  );
};

export default GoogleReportsChart;
