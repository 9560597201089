import {
  RoiFormatter,
  RoasFormatter,
  ValueDotFormatter,
  SpentFormatter,
} from 'components/tables/NinjaTable/formatters';

export const integrationMetricList = {
  ad_spent: {
    color: '#eb2f20',
    name: 'Ad Spent',
    formatter: SpentFormatter,
    show: true,
    type: 'bar',
  },
  net_profit: {
    color: '#91c716',
    name: 'Net Profit',
    valueFormatter: ValueDotFormatter,
    show: true,
    type: 'bar',
  },
  gross_profit: {
    color: '#536A8B',
    name: 'Gross Profit',
    valueFormatter: ValueDotFormatter,
    type: 'bar',
  },
  gross_income: {
    color: '#ffbb02',
    name: 'Gross Income',
    valueFormatter: ValueDotFormatter,
    type: 'bar',
  },
  roi: { color: '#ff4e00', name: 'ROI', type: 'line', formatter: RoiFormatter, opacity: 0.7 },
  roas: { color: '#F09319', name: 'ROAS', type: 'line', formatter: RoasFormatter, opacity: 0.7 },
  avg_order_value: { color: '#3B6F3A', name: 'Avg. Order Value', currency: true },
  cac: { color: '#5A8F53', name: 'CAC', currency: true },
  avg_profit_per_order: { color: '#7A9E5E', name: 'Avg. Profit Per Order', currency: true },
  new_customer_orders: { color: '#A0C68F', name: 'New Customer Orders' },
  orders: { color: 'rgb(145, 199, 22)', name: 'Orders' },
  deals_cogs: { color: '#9DC08B', name: 'COGS', currency: true },
  refund_rate: { color: '#9DC08B', name: 'Refund Rate', show: false, symbol: '%' },
  quantity: { color: '#9DC08B', name: 'Unit Sold', show: false },
  ltv: { color: '#9DC08B', name: 'Lifetime Value', show: false, currency: true },
};
