import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

const getYearMonth = (date) => date.year() * 12 + date.month();

dayjs.extend(isBetween);

export const disabledTwoWeeksDaysDate = (current, { from, type }) => {
  if (from) {
    const minDate = from.add(-14, 'days');
    const maxDate = from.add(14, 'days');
    switch (type) {
      case 'year':
        return current.year() < minDate.year() || current.year() > maxDate.year();
      case 'month':
        return (
          getYearMonth(current) < getYearMonth(minDate) ||
          getYearMonth(current) > getYearMonth(maxDate)
        );
      default:
        return Math.abs(current.diff(from, 'days')) >= 14;
    }
  }
  return false;
};


export const getMinTwoDaysRange = (from, to) => {
  if (!from)
    return {
      date_from: dayjs().subtract(2, 'days').format('YYYY-MM-DD'),
      date_to: dayjs().format('YYYY-MM-DD'),
    };
  const diffInDays = dayjs(to).diff(dayjs(from), 'days');
  if (diffInDays < 1) {
    return {
      date_from: dayjs(from).subtract(1, 'days').format('YYYY-MM-DD'),
      date_to: dayjs(from).format('YYYY-MM-DD'),
    };
  }
  return { date_from: from, date_to: to };
};

// Disabled 6 months from the selected date
export const disabled6MonthsDate = (current, { from, type }) => {
  if (from) {
    const minDate = from.add(-11, 'months');
    const maxDate = from.add(11, 'months');
    switch (type) {
      case 'year':
        return current.year() < minDate.year() || current.year() > maxDate.year();
      default:
        return (
          getYearMonth(current) < getYearMonth(minDate) ||
          getYearMonth(current) > getYearMonth(maxDate)
        );
    }
  }
  return false;
};

export const validate3YearDate = (current, { from, type }) => {
  if (from) {
    const minDate = from.add(-2, 'years');
    const maxDate = from.add(2, 'years');
    switch (type) {
      case 'year':
        return current.year() < minDate.year() || current.year() > maxDate.year();
      default:
        return (
          getYearMonth(current) < getYearMonth(minDate) ||
          getYearMonth(current) > getYearMonth(maxDate)
        );
    }
  }
  return false;
};
