import React from 'react';
import Widget from 'components/Widget/Widget';
import WooCommerceChartContent from './components/WooCommerceChartContent';
import { integrationMetricList } from '../components/IntegrationMetricList';

const WooCommerceReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  return (
    <Widget
      className='h-ful'
      tableWidget
      widget_key={'woocommerce_widget' + index}
      integrationType='woocommerce'
      title={data.title}
      showIntegrationFilters
      index={index}
      disableLimit
      disableStatusFilter
      disableAccountsFilter
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      load_chart={true}
      default_filter={data?.defaultFilter}
      icon={<img src='/icons/integrations/woocommerce.svg' className='w-7 h-7 ml-2' />}
      configuration={configuration}
      metricsList={integrationMetricList}
    >
      <WooCommerceChartContent height={height - 50} />
    </Widget>
  );
};

export default WooCommerceReportsChart;
