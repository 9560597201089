import React from 'react';
import Widget from 'components/Widget/Widget';
import ShopifyChartContent from './components/ShopifyChartContent';
import { integrationMetricList } from '../components/IntegrationMetricList';

const ShopifyReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;
  console.log(integrationMetricList, 'list');
  return (
    <Widget
      className='h-full'
      widget_key={'shopify_widget' + index}
      integrationType='shopify'
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableAccountsFilter
      showIntegrationFilters
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      configuration={configuration}
      icon={<img src='/icons/integrations/shopify.svg' className='w-7 h-7 ml-2' />}
      metricsList={integrationMetricList}
      load_chart={true}
    >
      <ShopifyChartContent height={height - 50} widget_key={'shopify_widget' + index} />
    </Widget>
  );
};

export default ShopifyReportsChart;
