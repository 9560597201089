import React, { forwardRef } from 'react';
import {
  BestAds,
  TotalProfitsAndLosses,
  ProfitsAndLossesByDays,
  WorstAds,
  StatsByAdAccount,
  GoogleQuickReports,
  MetaQuickReports,
  CRMSalesByDays,
  ShopifyWidget,
  BingQuickReports,
  TiktokQuickReports,
  ProfitsAndLossesByChannel,
  ProductSales,
  ProductReturns,
  ProductBundles,
  ProductSalesByDate,
  SalesByCountry,
} from './index';
import WoocommerceWidget from 'modules/DashboardModule/widgets/WoocommerceWidget';
import {
  PnLByDaysChart,
  ShopifyReportsChart,
  PnLByChannelsChart,
  WooCommerceReportsChart,
  NetworksCharts,
  GoogleReportsChart,
  MetaReportsChart,
  TikTokReportsChart,
  BingReportsChart,
} from '../charts';

export const widgetComponents = {
  daily_profit: {
    title: 'Total P&L',
    Component: TotalProfitsAndLosses,
    defaultFilter: {
      network_account_ids: [],
      adset_ids: [],
      ad_ids: [],
      campaign_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 2 },
      md: { x: 0, y: 0, w: 8, h: 4 },
      sm: { x: 0, y: 0, w: 8, h: 4 },
    },
  },
  facebook_daily_statistics: {
    title: 'Meta Quick Reports',
    Component: MetaQuickReports,
    defaultFilter: {
      network: 'facebook',
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 2 },
      md: { x: 0, y: 0, w: 8, h: 3 },
      sm: { x: 0, y: 0, w: 8, h: 3 },
    },
  },
  metaQuickChart: {
    title: 'Meta Reports Charts',
    config: { showXAxis: true, showYAxis: true },
    Component: MetaReportsChart,
    defaultFilter: {
      network: 'facebook',
      group_by: 'date',
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  tiktok_quick_reports: {
    title: 'TikTok Quick Reports',
    Component: TiktokQuickReports,
    defaultFilter: {
      network: 'tiktok',
      group_by: 'date',
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 2 },
      md: { x: 0, y: 0, w: 8, h: 3 },
      sm: { x: 0, y: 0, w: 8, h: 3 },
    },
  },
  tiktokReportsChart: {
    title: 'TikTok Reports Chart',
    config: { showXAxis: true, showYAxis: true },
    Component: TikTokReportsChart,
    defaultFilter: { network: 'tiktok', group_by: 'date', network_account_ids: [] },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  google_daily_statistics: {
    title: 'Google Quick Reports',
    Component: GoogleQuickReports,
    defaultFilter: {
      network: 'google',
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 2 },
      md: { x: 0, y: 0, w: 8, h: 3 },
      sm: { x: 0, y: 0, w: 8, h: 3 },
    },
  },
  googleReportsChart: {
    title: 'Google Reports Chart',
    Component: GoogleReportsChart,
    config: { showXAxis: true, showYAxis: true },
    defaultFilter: { network: 'google', group_by: 'date', network_account_ids: [] },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  bing_daily_statistics: {
    title: 'Bing Quick Reports',
    Component: BingQuickReports,
    defaultFilter: {
      network: 'bing',
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 2 },
      md: { x: 0, y: 0, w: 8, h: 3 },
      sm: { x: 0, y: 0, w: 8, h: 3 },
    },
  },
  bingReportsChart: {
    title: 'Bing Reports Chart',
    config: { showXAxis: true, showYAxis: true },
    Component: BingReportsChart,
    defaultFilter: {
      network: 'bing',
      group_by: 'date',
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  // sales_daily_statistics: {
  //   title: 'CRM Sales by Days',
  //   Component: CRMSalesByDays,
  //
  //   position: {
  //     lg: { x: 0, y: 0, w: 8, h: 2 },
  //     md: { x: 0, y: 0, w: 8, h: 3 },
  //     sm: { x: 0, y: 0, w: 8, h: 3 },
  //   },
  // },
  network_total_statistics: {
    title: 'Stats by Ad Account',
    Component: StatsByAdAccount,
    defaultFilter: {
      statuses: [],
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      adset_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 4 },
      md: { x: 0, y: 0, w: 4, h: 4 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  daily_statistics: {
    title: 'P&L by Days',
    Component: ProfitsAndLossesByDays,
    defaultFilter: {
      network_account_ids: [],
      group_by: 'date',
      adset_ids: [],
      ad_ids: [],
      campaign_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  PnLByDaysChart: {
    title: 'P&L by Days',
    Component: PnLByDaysChart,
    load_chart: true,
    config: { showXAxis: true, showYAxis: true },
    defaultFilter: {
      network_account_ids: [],
      group_by: 'date',
      adset_ids: [],
      ad_ids: [],
      campaign_ids: [],
    },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  profits_and_losses_by_channels: {
    title: 'P&L by Channels',
    Component: ProfitsAndLossesByChannel,
    defaultFilter: { network_account_ids: [] },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 4 },
      md: { x: 0, y: 0, w: 4, h: 4 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  PnLByChannelsChart: {
    title: 'P&L by Channels Chart',
    Component: PnLByChannelsChart,
    config: { showXAxis: true, showYAxis: true },
    defaultFilter: { network_account_ids: [], group_by: 'date' },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  ShopifyReportsChart: {
    title: 'Shopify Reports Chart',
    Component: ShopifyReportsChart,
    config: { showXAxis: true, showYAxis: true },
    defaultFilter: { integration_ids: [], group_by: 'date' },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 4 },
      md: { x: 0, y: 0, w: 4, h: 4 },
      sm: { x: 0, y: 0, w: 2, h: 4 },
    },
  },
  worst_ads: {
    title: 'Worst Ads',
    Component: WorstAds,
    defaultFilter: {
      statuses: [],
      network_account_ids: [],
      ad_ids: [],
      campaign_ids: [],
      network: null,
      limit: 10,
      level: 'adset',
    },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  best_ads: {
    title: 'Best Ads',
    Component: BestAds,
    defaultFilter: {
      statuses: [],
      network_account_ids: [],
      network: null,
      limit: 10,
      level: 'adset',
    },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  shopify_widget: {
    title: 'Shopify Quick Reports',
    Component: ShopifyWidget,
    defaultFilter: { integration_ids: [] },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 4 },
      md: { x: 0, y: 0, w: 4, h: 5 },
      sm: { x: 0, y: 0, w: 1, h: 7 },
    },
  },
  woocommerce_widget: {
    title: 'Woocommerce Quick Reports',
    Component: WoocommerceWidget,
    defaultFilter: { integration_ids: [] },
    position: {
      lg: { x: 0, y: 0, w: 8, h: 4 },
      md: { x: 0, y: 0, w: 4, h: 5 },
      sm: { x: 0, y: 0, w: 4, h: 7 },
    },
  },
  WooCommerceReportsChart: {
    title: 'Woocommerce Reports Chart',
    Component: WooCommerceReportsChart,
    config: { showXAxis: true, showYAxis: true },
    defaultFilter: { integration_ids: [], group_by: 'date' },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  networks_chart: {
    title: 'Networks Overview',
    Component: NetworksCharts,
    config: { showXAxis: true, showYAxis: true },
    defaultFilter: { integration_ids: [], group_by: 'date' },
    position: {
      lg: { x: 0, y: 0, w: 3, h: 3 },
      md: { x: 0, y: 0, w: 3, h: 3 },
      sm: { x: 0, y: 0, w: 4, h: 4 },
    },
  },
  product_sales: {
    title: 'Product Sales',
    Component: ProductSales,
    defaultFilter: { integration_ids: [], integration_type: '' },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  product_returns: {
    title: 'Product Returns',
    Component: ProductReturns,
    defaultFilter: { integration_ids: [], integration_type: '' },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  product_bundles: {
    title: 'Product Bundles',
    Component: ProductBundles,
    defaultFilter: { integration_ids: [], integration_type: '' },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
  // product_sales_by_date: {
  //   title: 'Product Sales By Date',
  //   Component: ProductSalesByDate,
  //   defaultFilter: { integration_ids: [], integration_type: '' },
  //   position: {
  //     lg: { x: 0, y: 0, w: 4, h: 6 },
  //     md: { x: 0, y: 0, w: 4, h: 6 },
  //     sm: { x: 0, y: 0, w: 4, h: 6 },
  //   },
  // },
  sales_by_country: {
    title: 'Sales By Country',
    Component: SalesByCountry,
    defaultFilter: { integration_ids: [], integration_type: '' },
    position: {
      lg: { x: 0, y: 0, w: 4, h: 6 },
      md: { x: 0, y: 0, w: 4, h: 6 },
      sm: { x: 0, y: 0, w: 4, h: 6 },
    },
  },
};
