import { Card, Space, Popover, Button } from 'antd';
import React, { createContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import WidgetCustomize from 'components/Widget/WidgetCustomize';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import { getDateRangeByKey } from 'components/DateRangePicker/components/staticRanges';
import WidgetTitle from './components/WidgetTitle';
import DisplayFilteredTag from './components/DisplayFilteredTag';
import Configuration from './components/Configuration';
import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';
import Ninja from '@ninja';
import DateRangePicker from 'components/DateRangePicker';
import WidgetTypeSwitcher from './components/WidgetTypeSwitcher';

export const WidgetContext = createContext({});
const Widget = (props) => {
  const {
    // Widghet Params
    widget_key,
    title,
    index,
    tableWidget,
    movingButton,
    icon,

    // filters Params
    disableLevelFilter,

    disableLimit,
    disableDateFilter,
    disableStatusFilter,
    showIntegrationFilters,
    integrationsSelect,
    disableAccountsFilter,
    showWoocommerceFilter,
    integrationType,
    default_filter,
    networkSwitch = true,
    adsFilter,
    adsetFilter,
    campaignFilter,

    // Charts Params
    enableChart = false,
    metricOptions,
    network = 'all',
    configuration,
    showWidgetTypeSwitcher,
    customize = false,
    metricsList,
    data,
    load_chart = false,
    DisableFilterButton = true,
    showDatePicker = false,
    ...card
  } = props;

  const [runFilterTimestamp, setRunFilterTimestamp] = useState(new Date().getTime());
  const { config, updateWidget, isLoading } = useDashboardLayout();
  const { date_from, date_to, setDate, date_range_key } = useWidgetDate(widget_key);
  const [filters, setFilters] = useState(default_filter);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [chartConfig, setChartConfig] = useState(
    data?.config || {
      load_chart: data?.config?.load_chart || load_chart,
      metrics: {},
      showXAxis: false,
      showYAxis: false,
    }
  );

  useEffect(() => {
    setChartConfig((prevConfig) => ({
      ...prevConfig,
      ...data?.config,
      load_chart: load_chart,
    }));
  }, [load_chart, data?.config]);

  useEffect(() => {
    if (data?.config?.metrics || metricsList) {
      const updatedMetrics = Object.fromEntries(
        Object.entries(data?.config?.metrics || metricsList)
          .filter(([, { show }]) => show === true)
          .map(([key, { color, name, show, type }]) => [
            key,
            { type, color, name, show: show || false },
          ])
      );
      setChartConfig({
        load_chart: data?.config?.load_chart || load_chart,
        areaConfig: data?.config?.areaConfig || null,
        metrics: updatedMetrics,
        showXAxis: data?.config?.showXAxis || false,
        showYAxis: data?.config?.showYAxis || false,
      });
    }
  }, [metricsList, data]);

  useEffect(() => {
    const { date_from: key_date_from, date_to: key_date_to } = date_range_key
      ? getDateRangeByKey(date_range_key)
      : { date_from, date_to };

    setFilters((prevFilters) => ({
      ...prevFilters,
      date_range_key: date_range_key || null,
      date_from: key_date_from,
      date_to: key_date_to,
    }));
  }, [date_from, date_to, date_range_key]);

  useEffect(() => {
    const { date_from, date_to } = default_filter?.date_range_key
      ? getDateRangeByKey(default_filter.date_range_key)
      : { date_from: default_filter?.date_from, date_to: default_filter?.date_to };

    if (date_from && date_to) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        date_from,
        date_to,
      }));

      setDate({ date_from, date_to, date_range_key: default_filter?.date_range_key || null });
    }
  }, [default_filter]);

  const handleDelete = () => {
    const updatedConfig = config.filter((_, i) => i !== index);
    updateWidget(updatedConfig);
  };

  const runFilter = () => {
    setRunFilterTimestamp(new Date().getTime());
  };

  const changeLevel = (level) => {
    setFilters((prevFilters) => ({ ...prevFilters, level: level.target.value }));
    runFilter();
    props.onLevelChange?.(level.target.value);
  };

  const changeLimit = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, limit: value }));
    runFilter();
  };

  const changeWidgetType = (val) => {
    setChartConfig((prevConfig) => ({
      ...prevConfig,
      load_chart: val,
    }));
  };

  return (
    <WidgetContext.Provider
      value={{
        widget_key,
        title,
        filters,
        setFilters,
        runFilterTimestamp,
        runFilter,
        default_filter,
        chartConfig,
        setChartConfig,
        network,
        networkSwitch,
        metricsList,
        index,
        disableStatusFilter,
        showIntegrationFilters,
        integrationsSelect,
        showWoocommerceFilter,
        disableAccountsFilter,
        disableLevelFilter,
        changeLevel,
        disableDateFilter,
        showDateFilter,
        setShowDateFilter,
        adsFilter,
        adsetFilter,
        campaignFilter,
        showWidgetTypeSwitcher,
        DisableFilterButton,
        widget_filters: {
          ...filters,
        },
      }}
    >
      {configuration ? (
        <Configuration onChange={changeWidgetType} value={chartConfig?.load_chart}>
          {props.children}
        </Configuration>
      ) : (
        <Card
          title={<WidgetTitle icon={icon} title={title} />}
          extra={
            <Space className='flex gap-2 items-center w-full'>
              <div className='flex items-center gap-1'>
                {(!disableDateFilter || showDatePicker) && (
                  <DateRangePicker
                    variant='outlined'
                    placement='bottomRight'
                    className='h-10 mr-2'
                    style={{ maxWidth: 150 }}
                    formatter='yyyy-MM-dd'
                    changeGlobal={true}
                    placeholder={['Date from', 'Date to']}
                    date_key={widget_key}
                    globalDate={false}
                    value={[filters?.date_from, filters?.date_to]}
                    icon={false}
                  />
                )}
                {showWidgetTypeSwitcher && (
                  <WidgetTypeSwitcher
                    showLabel={false}
                    onChange={changeWidgetType}
                    value={chartConfig?.load_chart}
                  />
                )}
                <Popover
                  placement='bottom'
                  mouseLeaveDelay={0.7}
                  content={
                    <DisplayFilteredTag
                      handleDelete={handleDelete}
                      integrationType={integrationType}
                      default_filter={default_filter}
                      index={index}
                      widget_key={widget_key}
                      disableDateFilter={disableDateFilter}
                    />
                  }
                >
                  <Button
                    type='text'
                    size='large'
                    icon={<Icon path={mdiDotsVertical} color={Ninja.colors.darkText} />}
                  />
                </Popover>
                {customize && (
                  <WidgetCustomize
                    disableLevelFilter={disableLevelFilter}
                    changeLevel={changeLevel}
                    filters={filters}
                    changeLimit={changeLimit}
                    defaultPerPage={filters?.limit || 10}
                    disableLimit={disableLimit}
                    handleDelete={handleDelete}
                    index={index}
                    editTitle={true}
                    deleteLoading={isLoading}
                    onEdit={() => setEditing(true)}
                  />
                )}
              </div>
              {movingButton}
            </Space>
          }
          className={clsx('widget-card w-full', { 'table-widget': tableWidget })}
          {...card}
        >
          {props.children}
        </Card>
      )}
    </WidgetContext.Provider>
  );
};

export default Widget;
