import React from 'react';
import { Segmented } from 'antd';
import Icon from '@mdi/react';
import { mdiTable, mdiChartLine } from '@mdi/js';
import Ninja from '@ninja';

const WidgetTypeSwitcher = ({ showLabel = true, ...props }) => {
  return (
    <Segmented
      size='large'
      options={[
        {
          value: true,
          label: showLabel ? <span className='font-normal text-sm'>Line Charts</span> : null,
          icon: <Icon path={mdiChartLine} color={Ninja.colors.darkText} />,
        },
        {
          value: false,
          icon: <Icon path={mdiTable} color={Ninja.colors.darkText} />,
          label: showLabel ? <span className='font-normal text-sm'>Cards</span> : null,
        },
      ]}
      {...props}
    />
  );
};

export default WidgetTypeSwitcher;
